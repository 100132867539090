export const charges = [
  {
    defendant: 'Syed Nasir Ali Shah',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Bolton, ON',
    oLocation: 'Simcoe, ON',
    chargeDate: 'July 5, 2021',
    charge:
      'ONHWPA - Section 6 - Officer/Director of a Company who Illegally acted as a Vendor of a New Home'
  },
  {
    defendant: 'Exquisite Bay Development Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Mississauga, ON',
    chargeDate: 'January 19, 2024',
    charge: 'Section 37(1) – Vendor NHCLA'
  },
  {
    defendant: 'Exquisite Bay Development Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Mississauga, ON',
    chargeDate: 'January 19, 2024',
    charge: 'Section 37(1) – Vendor NHCLA'
  },
  {
    defendant: 'Ahmed Raza Yousuf',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Mississauga, ON',
    chargeDate: 'January 19, 2024',
    charge: 'Section 37(1) – Vendor Officer/Director NHCLA'
  },
  {
    defendant: 'Ahmed Raza Yousuf',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Mississauga, ON',
    chargeDate: 'January 19, 2024',
    charge: 'Section 37(1) – Vendor Officer/Director NHCLA'
  },
  {
    defendant: 'Matoma Corporation',
    link: 'https://obd.hcraontario.ca/unlicensed/B46630',
    dLocation: 'Barrie, ON',
    oLocation: 'The Blue Mountains, ON',
    chargeDate: 'January 16, 2024',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home'
  },
  {
    defendant: 'Matoma Corporation',
    link: 'https://obd.hcraontario.ca/unlicensed/B46630',
    dLocation: 'Barrie, ON',
    oLocation: 'The Blue Mountains, ON',
    chargeDate: 'January 16, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Tomas Joseph Pavlovic',
    link: 'https://obd.hcraontario.ca/unlicensed/100466551',
    dLocation: 'Mississauga, ON',
    oLocation: 'The Blue Mountains, ON',
    chargeDate: 'January 16, 2024',
    charge:
      'ONHWPA - Section 6 - Officer/Director of a Company who Illegally acted as a Builder of a New Home'
  },
  {
    defendant: 'Tomas Joseph Pavlovic',
    link: 'https://obd.hcraontario.ca/unlicensed/100466551',
    dLocation: 'Mississauga, ON',
    oLocation: 'The Blue Mountains, ON',
    chargeDate: 'January 16, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Mashiat Mutmainnah',
    link: 'https://obd.hcraontario.ca/unlicensed/B63488',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'NHCLA - Section 37(1) – Acted as a vendor of a new home without a licence'
  },
  {
    defendant: 'Shaheda Karim Jesmin',
    link: 'https://obd.hcraontario.ca/unlicensed/B63487',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home'
  },
  {
    defendant: 'Kanada Homes and Property Construction Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B63486',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'Section 71(1)(b) - Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 10.2(4) - Builder fail to Enrol (shall not commence construction)'
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'NHCLA - Section 37(2) - Acted as a builder of a new home without a licence'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'NHCLA - Section 37(1) - Officer/Director of a Company who acted as a vendor of a new home without a licence'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'NHCLA - Section 37(2) -Officer/Director of a Company who acted as a builder of a new home without a licence'
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'POA - Section 77(1) - Knowingly acted as a Party to an Offence '
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'POA - Section 77(1) - Knowingly acted as a Party to an Offence '
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'Section 71(1)(b) – Officer/Director  of a Company who Fails to comply with Condition of Licence NHCLA'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'POA - Section 77(1) - Knowingly acted as a Party to an Offence '
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'POA - Section 77(1) - Knowingly acted as a Party to an Offence '
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home'
  },
  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 21, 2024',
    charge:
      'NHCLA - Section 37(2) - Officer/Director of a Company who acted as a vendor of a new home without a licence'
  }

  //   defendant: 'Al-Leem Kassam o/a Creative Design & Construction',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B61137',
  //   dLocation: 'Richmond Hill, ON',
  //   oLocation: 'Port McNicoll, ON',
  //   chargeDate: 'November 21, 2023',
  //   charge: 'Section 37(2) – Builder NHCLA'
  // },
  // {
  //   defendant: 'Al-Leem Kassam o/a Creative Design & Construction',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B61137',
  //   dLocation: 'Richmond Hill, ON',
  //   oLocation: 'Port McNicoll, ON',
  //   chargeDate: 'November 21, 2023',
  //   charge: 'Section 37(2) – Builder NHCLA'
  // },
  // {
  //   defendant: 'Al-Leem Kassam o/a Creative Design & Construction',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B61137',
  //   dLocation: 'Richmond Hill, ON',
  //   oLocation: 'Port McNicoll, ON',
  //   chargeDate: 'November 21, 2023',
  //   charge: 'Section 71(1)(c) – Fails to comply with any order NHCLA'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge: 'Section 37(2) – Builder NHCLA'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge:
  //     'ONHWPA - Section 10.2(3) - Builder Fail to Enrol (enter into contract)'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge:
  //     'ONHWPA - Section 6 - Officer/Director of a Company who Illegally acted as a Builder of a New Home'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge:
  //     'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge: 'Section 37(2) – Builder O/D NHCLA'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge:
  //     'ONHWPA - Section 10.2(3) - Officer/Director Builder Fail to Enrol (Enter into Contract)'
  // }
]
